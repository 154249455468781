import { Link } from "components/link/Link";
import { NavButton } from "components/nav/NavButton";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import s from './Downloadables.scss';
import Arrow from 'assets/svg/download-arrow-new.svg';
import { Button } from "components/button/Button";
import { Container } from "components/container/Container";
import DArrowSmall from 'assets/svg/download-arrow-small-orange.svg'


const Item = ({item}: any) => {
    const {image, file, title} = item;
    return (
        <a className={s.item} href={file.file.url} target='_blank' download>
            <div className={s.imageContainer}>
                <GatsbyImage class={s.image} alt={image.description} image={image.gatsbyImageData}/>
            </div>
            <div className={s.itemLink}>
                {title} <Arrow style={{height: 20, width: 21}}/>
            </div>
        </a>
    )
}

export const Downloadables = ({list, style}: any) => {
    const {title, items, text, cta} = list;
    return (
    <Container>
        <div className={s.container} style={style}>
            <div className={s.header}>
            {
                title &&
                <h2 className={s.title}>{title}</h2>
            }
            {
                text &&
                <div className={s.text}>
                    {renderRichText(text)}
                </div>
            }
            </div>
            <div className={`${s.content} ${items.length < 3 && s.flexContent}`}>
                {
                    items.map((item: any, i: number) => (
                        <Item key={i} item={item}/>
                    ))
                }
            </div>
            {
                cta &&
                <a className={s.cta} href={cta.href} target="_blank">
                    {cta.label}
                    <DArrowSmall className={s.arrow}/>           
                </a>
            }     
        </div>
    </Container>
    )
}